import dynamic from 'next/dynamic';
import { SLICE_TYPES } from 'const/index';
import GoogleMap from './ShowPages/GoogleMap';
import Background from './slices/Background';
import Breadcrumb from './slices/Breadcrumb';
import ContentTabs from './slices/ContentTabs';
import FooterLinksSection from './slices/FooterLinksSection';
import HorizontalLine from './slices/HorizontalLine';
import PageTabs from './slices/PageTabs';
import RichTextBox from './slices/RichTextBox';
import RichtextWithCTA from './slices/RichTextWithCTA';
import Table from './slices/Table';
import TicketCard from './slices/TicketCardSlice';
import UGCCarousel from './slices/UGCCarousel';
import VerticalCardsGrid from './VenuePage/components/VerticalCardsGrid';

// Dynamic imports
const CustomLinkedTours = dynamic(() => import('./slices/CustomLinkedTours'));
const ImageGallery = dynamic(() => import('./slices/ImageGallery'));
const CarouselGallery = dynamic(
  () =>
    import(/* webpackChunkName: "CarouselGallery" */ './slices/CarouselGallery')
);
const TicketCards = dynamic(() => import('./slices/TicketCards'));
const ImageLinksCarousel = dynamic(() => import('./slices/ImageLinksCarousel'));
const InteractiveImage = dynamic(() => import('./slices/InteractiveImage'));
const TrustBoosters = dynamic(() => import('./slices/TrustBoosters'));
const TourComparisonTable = dynamic(() => import('./slices/TourComparision'));
const AutomatedComparisonTable = dynamic(
  () => import('./slices/AutomatedComparisonTable')
);
const ImageGrid = dynamic(() => import('./slices/ImageGrid'));
const ImageTextGrid = dynamic(() => import('./slices/ImageTextGrid'));
const InternalContentCard = dynamic(
  () => import('./slices/InternalContentCard')
);
const FWActionCard = dynamic(() => import('./slices/FWActionCard'));
const FeatureBox = dynamic(() => import('./slices/FeatureBox'));
const CardCarousel = dynamic(() => import('./slices/CardCarousel'));
const CategorySection = dynamic(() => import('./MicrositeV2/CategorySection'));
const CategorySlider = dynamic(() => import('./MicrositeV2/CategorySlider'));
const MicrobrandCards = dynamic(() => import('./slices/MicrobrandCards'));
const TabWrapper = dynamic(() => import('./slices/TabWrapper'));
const Tab = dynamic(() => import('./slices/Tab'));
const SliderAccordion = dynamic(() => import('./slices/SliderAccordion'));
const CardSection = dynamic(() => import('./slices/CardSection'));
const Card = dynamic(() => import('./slices/Card'));
const TableV2 = dynamic(() => import('./slices/TableV2'));
const TableV3 = dynamic(() => import('./slices/TableV3'));
const AlertPopup = dynamic(() => import('./slices/AlertPopup'));
const AnchorPoint = dynamic(() => import('./slices/AnchorPoint'));
const BlogFeed = dynamic(() => import('./slices/BlogFeed'));
const AccordionGroup = dynamic(() => import('./slices/AccordionGroup'));
const ListicleSection = dynamic(() => import('./slices/ListicleSection'));
const Listicle = dynamic(() => import('./slices/Listicle'));
const ListicleV2 = dynamic(() => import('./slices/ListicleV2/index'));
const ListicleSectionV2 = dynamic(
  () => import('./slices/ListicleSectionV2/index')
);
const Reviews = dynamic(() => import('./slices/Reviews'));
const ExperienceCarousel = dynamic(
  () => import('./GlobalMbs/Carousels/ExperienceCarousel')
);
const CollectionCarousel = dynamic(
  () => import('./GlobalMbs/Carousels/CollectionCarousel')
);
const StructuredCard = dynamic(
  () =>
    import(/* webpackChunkName: "StructuredCard" */ './slices/StructuredCard')
);

const sliceHandler = (slice: any, props: any = {}) => {
  if (slice?.primary?.hide_slice) return null;

  if (props?.isRevampedDesign) {
    if (slice?.slice_type === 'accordion') {
      const { isRevampedDesign, isMobile } = props;
      return (
        <AccordionGroup
          accordions={slice?.items}
          heading={slice?.primary?.heading}
          useSchema={slice?.primary?.use_faq_schema || false}
          sliceProps={props}
          tabData={slice?.items}
          isRevampedDesign={isRevampedDesign}
          isMobile={isMobile}
        />
      );
    } else return null;
  }

  switch (slice.slice_type) {
    case 'rich_text':
    case 'rich_text_only':
      return (
        <RichtextWithCTA childSlices={slice.items} isMobile={props.isMobile} />
      );
    case 'image':
    case 'image_grid':
      return (
        <ImageGrid
          cols={slice.primary.number_of_columns || 1}
          images={slice.items}
        />
      );
    case 'image_text_combo_grid':
      return (
        <ImageTextGrid
          cols={slice.primary.number_of_columns || 3}
          cards={slice.items}
        />
      );
    case 'page_tabs':
      return (
        <PageTabs
          tabs={slice.items}
          align={slice.primary?.tab_alignment || 'center'}
        />
      );
    case 'internal_content_card':
      return (
        <InternalContentCard title={slice.primary.title} cards={slice.items} />
      );
    case 'full_width_action_card':
      return <FWActionCard title={slice.primary.title} cards={slice.items} />;
    case 'content_box':
      return <RichTextBox childSlices={slice.items} key={slice.index} />;
    case 'feature_box':
      return <FeatureBox blocks={slice.items} lazyLoad={true} />;
    case 'footer_column':
      return (
        <FooterLinksSection
          title={slice.primary.heading}
          links={slice.items}
          sliceLength={props.sliceLength}
          sliceIndex={props.index}
        />
      );
    case 'table':
      return (
        <Table
          title={slice.primary.table_heading}
          numberOfColumns={slice.primary.number_of_column}
          columnsData={slice.items}
        />
      );
    case 'card_carousel':
      if (slice.items.length)
        return (
          <CardCarousel
            carouselHeading={slice.primary.carousel_heading}
            cards={slice.items}
          />
        );
      break;
    case 'category_carousel':
      const tgidArray = slice?.primary?.csv_tgids
        ?.split(',')
        ?.map((tgid: any) => parseInt(tgid.trim()));
      if (props.isMobile)
        return (
          <CategorySection
            {...props}
            tgidsArray={tgidArray}
            description={slice?.primary?.carousel_description}
            heading={slice?.primary?.carousel_heading}
            category={slice?.primary?.category_id}
            excludedTgids={slice?.primary?.exclude_tgids}
          />
        );
      else
        return (
          <CategorySlider
            {...props}
            tgidsArray={tgidArray}
            isFirstTourOpen={slice?.primary?.is_first_tour_open}
            description={slice?.primary?.carousel_description}
            heading={slice?.primary?.carousel_heading}
            category={slice?.primary?.category_id}
            excludedTgids={slice?.primary?.exclude_tgids}
          />
        );
    case 'trust_boosters':
      let boosters = slice.items.reduce((accum: any, item: any) => {
        let booster = {
          image_url: item.icon_url.url || item.uploaded_icon.url,
          title: item.booster_title,
          description: item.booster_description,
        };
        return [...accum, booster];
      }, []);
      return <TrustBoosters boosters={boosters} {...props} />;
    case 'microbrand_cards':
      return (
        <MicrobrandCards cards={slice.items} cardsContent={slice.primary} />
      );
    case 'comparision_table':
      const orderedLabels = slice.items.reduce((acc: [], label: any) => {
        return [
          ...acc,
          {
            label: label.selected_labels.slug,
            labelId: label.selected_labels.id,
          },
        ];
      }, []);
      const vendor = slice.primary.csv_vendors
        ?.split(',')
        .map((v: any) => v.trim());
      const vendorLinks = slice.primary.csv_vendor_links
        ?.split(',')
        .map((v: any) => v.trim());
      return (
        <TourComparisonTable
          isMobile={props.isMobile}
          heading={slice.primary.comparision_heading}
          description={slice.primary.comparison_description}
          tgidsCSV={slice.primary.product_tgids}
          vendors={vendor}
          vendorLinks={vendorLinks}
          orderedLabels={orderedLabels}
          slice={slice}
          designType={slice.primary.design_type}
          showImage={slice.primary.show_image}
        />
      );
    case 'interactive_image':
      const { primary } = slice;
      const url = primary.linked_image.url || primary.uploaded_image.url;
      const alt = primary.uploaded_image.alt || 'Popup Image';
      return <InteractiveImage src={url} alt={alt} isMobile={props.isMobile} />;
    case 'image_links_carousel':
      const imagesInfo = slice.items.reduce((acc: any, card: any) => {
        return [
          ...acc,
          {
            image: {
              url: card.linked_image.url || card.uploaded_image.url,
              alt: card.image_alt || card.uploaded_image.alt,
            },
            link: card.card_link,
            card_title: card.card_title,
          },
        ];
      }, []);
      return (
        <ImageLinksCarousel
          description={slice.primary.carousel_description}
          clickInteraction={slice.primary.click_interaction}
          heading={slice.primary.carousel_heading}
          images={imagesInfo}
          isMobile={props.isMobile}
        />
      );
    case 'category_section':
      const tgids = slice.primary.csv_tgids
        .split(',')
        .map((tgid: any) => parseInt(tgid.trim()));
      return (
        <CategorySection
          {...props}
          tgidsArray={tgids}
          isFirstTourOpen={slice.primary.is_first_tour_open}
          description={slice.primary.carousel_description}
          heading={slice.primary.carousel_heading}
        />
      );
    case 'content_tabs':
      return (
        <ContentTabs
          tabsArr={slice.primary.tab_list.split(',').map((t: any) => t.trim())}
          contentArr={slice.items}
        />
      );

    case 'tab_wrapper':
      return (
        <TabWrapper
          childSlices={slice.slices}
          heading={slice.primary.title}
          sliceProps={props}
          description={slice.primary.description}
        />
      );
    case 'tab':
      return (
        <Tab
          key={props.index}
          childSlices={slice.slices}
          title={slice.primary.title}
          isDefault={slice.primary.is_default == 'Yes'}
          sliceProps={props}
        />
      );
    case 'question':
      const faqs = slice.items.reduce((acc: any, slice: any) => {
        const images = slice.items.reduce((acci: any, image: any) => {
          let img = {
            url: image.linked_image || image.upload_image?.url,
            caption: image.upload_image?.alt || image.image_caption,
            alt: image.alt_text,
          };
          return [...acci, img];
        }, []);
        return [
          ...acc,
          {
            question: slice.primary.question,
            answer: slice.primary.answer,
            images,
          },
        ];
      }, []);
      return (
        <SliderAccordion
          key={props.index}
          isMobile={props.isMobile}
          faqs={faqs}
          sliceProps={props}
        />
      );
    case 'table_v2':
      const rows =
        slice.slices
          .filter((t_slice: any) => t_slice.slice_type === 'table_rows')[0]
          ?.items?.reduce((acc: any, row: any) => {
            return [
              ...acc,
              {
                columns: row.items,
                primary: row.primary,
              },
            ];
          }, []) || [];
      return (
        <TableV2
          title={slice.primary.title}
          description={slice.primary.description}
          rows={rows}
          isMobile={props.isMobile}
        />
      );

    case SLICE_TYPES.TABLE_V3: {
      const { primary, slices } = slice || {};
      const { table_heading, display_serial_number, serial_number_heading } =
        primary || {};
      return (
        <TableV3
          title={table_heading}
          childSlices={slices}
          displaySerialNum={display_serial_number}
          serialNumHeading={serial_number_heading}
        />
      );
    }

    case 'card_section':
      const {
        card_section_title,
        card_section_type,
        cards_in_a_row,
        description,
        exit_description,
      } = slice.primary;

      const cardSectionTitle =
        props?.isGlobalCollection &&
        (card_section_title?.trim() === 'Explore' ||
          card_section_title?.trim() === 'Things To Do')
          ? `${card_section_title} ${props?.collectionName}`
          : card_section_title;

      return (
        <CardSection
          childSlices={slice.slices}
          title={cardSectionTitle}
          sectionType={card_section_type}
          cardsInARow={Number(cards_in_a_row) || 1}
          description={description}
          exitDescription={exit_description}
          sectionName={props.sectionName}
          {...props}
        />
      );
    case 'card':
      const {
        card_title,
        card_description,
        cta_text,
        cta_link,
        card_link,
        card_link_type,
        cta_type,
      } = slice.primary;
      const images = slice.items
        .filter((image: any) => {
          if (image.image_url.url || image.image_source.url) return true;
        })
        .map((image: any) => ({
          url: image.image_url.url || image.image_source.url,
          alt: image.image_alt || image.image_source.alt,
          copyright: image.image_source.copyright,
          video: image.video_url.url,
        }));

      return (
        <Card
          key={props.index}
          images={images}
          title={card_title}
          description={card_description}
          cta={{ text: cta_text, link: cta_link, type: cta_type ?? 'Button' }}
          type={props.cardType}
          cardsInARow={props.cardsInARow}
          link={card_link}
          linkType={card_link_type}
          isGlobalMb={props.isGlobalMb}
          isMobile={props.isMobile}
          sectionName={props.sectionName}
          isSeatMapExpControlAndEligible={props.isSeatMapExpControlAndEligible}
        />
      );
    case 'horizontal_line':
      return <HorizontalLine />;
    case 'breadcrumbs':
      if (props.automatedBreadcrumbsExists) return null;

      const orderedLinks = slice.items.reduce((acc: any, crumb: any) => {
        return [
          ...acc,
          {
            text: crumb.title,
            link: crumb.url,
          },
        ];
      }, []);
      orderedLinks.push({ text: slice.primary.current_title, url: {} });
      return <Breadcrumb orderedLinks={orderedLinks} />;
    case 'background':
      return (
        <Background
          color={slice.primary.color}
          textCenter={slice.primary.centered}
          gridCenter={slice.primary.grid_center}
          childSlices={slice.slices}
          sliceProps={props}
        />
      );
    case 'alert':
      return (
        <AlertPopup
          images={slice.items || []}
          title={slice.primary.alert_title || 'Your safety is our Priority'}
          description={slice.primary.alert_description || []}
        />
      );
    case 'image_gallery':
      return (
        <ImageGallery
          heading={slice.primary.heading}
          images={slice.items}
          isMobile={props.isMobile}
          $isNewsPage={props.isNewsPage}
        />
      );
    case 'carousel_gallery':
      return (
        <CarouselGallery heading={slice.primary.heading} images={slice.items} />
      );
    case 'ticket_cards':
      return (
        <TicketCards
          title={slice.primary.title}
          cards={slice.items}
          twoColumns={slice.primary.show_2_cards_in_a_row}
        />
      );
    case 'anchor_point':
      return <AnchorPoint id={slice.primary.id} />;
    case 'blog_feed':
      return (
        <BlogFeed
          count={slice.primary.count}
          feed_url={slice.primary.feed_url}
        />
      );
    case 'custom_linked_tours':
      const { content, common_page_link } = slice.primary;
      const c_tgids: any = [];
      const tours = slice.items.reduce((acc: any, tour: any) => {
        c_tgids.push(tour.tgid);
        return {
          ...acc,
          [tour.tgid]: { tgid: tour.tgid, ...tour.link_override },
        };
      }, {});
      return (
        <CustomLinkedTours
          tours={tours}
          tgids={c_tgids}
          content={content}
          commonLink={common_page_link}
        />
      );
    case 'accordion':
    case SLICE_TYPES.TABBED_INFO:
      const { isMobile, isVenuePage, findBestSeatsCallback } = props;

      if (
        isVenuePage &&
        !isMobile &&
        slice.slice_type === SLICE_TYPES.TABBED_INFO
      ) {
        return (
          <TabWrapper
            heading={slice?.primary?.heading}
            sliceProps={''}
            tabData={slice?.items}
            findBestSeatsCtaCallback={findBestSeatsCallback}
          />
        );
      } else {
        return (
          <AccordionGroup
            isMobile={isMobile}
            accordions={slice.items}
            heading={slice.primary.heading}
            useSchema={slice.primary.use_faq_schema || false}
            sliceProps={props}
            headingNeedsSeparator={isVenuePage}
            tabData={slice?.items}
            isVenuePage={props.isVenuePage}
            findBestSeatsCtaCallback={findBestSeatsCallback}
          />
        );
      }
    case 'unspace':
      return <div className="unspace-slice" />;

    case SLICE_TYPES.LISTICLE_SECTION: {
      const {
        primary: { section_title, listicle_type },
        slices,
      } = slice;
      return (
        <ListicleSection
          title={section_title}
          type={listicle_type?.toLowerCase()}
          childSlices={slices}
        />
      );
    }

    case SLICE_TYPES.LISTICLE: {
      const { type, index } = props;
      return <Listicle key={index} type={type} index={index} data={slice} />;
    }

    case SLICE_TYPES.LISTICLE_SECTION_V2_START: {
      const {
        primary: { listicle_title, listicle_type, settings_type },
        slices,
      } = slice;
      const { prismicDocsForListicle, collectionsInListicles } = props;
      return (
        <ListicleSectionV2
          settings={settings_type}
          title={listicle_title}
          type={listicle_type?.toLowerCase()}
          childSlices={slices}
          prismicDocsForListicle={prismicDocsForListicle}
          collectionsInListicles={collectionsInListicles}
        />
      );
    }

    case SLICE_TYPES.LISTICLE_V2:
      const { type, settings, title, index } = props;
      return (
        <ListicleV2
          type={type}
          items={slice?.items}
          settings={settings}
          listicleSectionTitle={title}
          index={index}
        />
      );

    case 'reviews':
      return (
        <Reviews
          title={slice.primary.title}
          type={slice.primary.type}
          reviews={slice.items}
          isMobile={props.isMobile}
          showNewDesign={props.isHOHORevamp}
        />
      );

    case 'global_experiences':
      const experienceCarouselTitle =
        props?.isGlobalCollection &&
        slice?.primary?.experience_type === 'Tickets'
          ? `${props?.collectionName} ${slice?.primary?.title ?? ''}`
          : slice?.primary?.title;
      return (
        <ExperienceCarousel
          cardsInARow={slice?.primary?.cards_in_a_row}
          experienceType={slice?.primary?.experience_type}
          mbType={slice?.primary?.mb_type}
          showSeeAll={slice?.primary?.show_see_all}
          title={experienceCarouselTitle}
          {...props}
        />
      );
    case 'collection_carousel':
      return (
        <CollectionCarousel
          title={slice?.primary?.carousel_title}
          subtext={slice?.primary?.carousel_subtext}
          carouselType={slice?.primary?.carousel_type}
          showSeeAll={slice?.primary?.show_see_all}
          {...props}
        />
      );
    case 'tours_list':
      return props.tourListSection;
    case 'ticket_card_shoulder_page':
      return (
        <TicketCard
          subtext={slice?.primary?.subtext}
          title={slice?.primary?.title}
          {...props}
        />
      );
    case 'automated_comparison_table':
      return (
        <AutomatedComparisonTable
          isMobile={props.isMobile}
          heading={slice?.primary?.section_title}
          description={slice?.primary?.section_sub_heading}
          collectionId={slice?.primary?.collection_id}
        />
      );
    case 'ugc_carousel':
      return (
        <UGCCarousel
          isMobile={props.isMobile}
          heading={slice?.primary?.title}
          subHeading={slice?.primary?.sub_title}
          cards={slice?.items}
        />
      );

    case SLICE_TYPES.VERTICAL_CARD_GRIDS:
      return (
        <VerticalCardsGrid
          data={slice.items}
          isMobile={props.isMobile}
          heading={slice.primary.heading}
        />
      );

    case SLICE_TYPES.GOOGLE_MAP:
      return (
        <GoogleMap
          mapURL={slice.primary.google_map_url.url}
          isVenuePage={props.isVenuePage}
        />
      );

    case SLICE_TYPES.STRUCTURED_CARD:
      const {
        intro_text,
        outro_text,
        timings,
        frequency,
        duration,
        cta_text: structured_card_cta_text,
        cta_url,
        card_image_url,
        image_alt,
      } = slice.primary || {};
      return (
        <StructuredCard
          introText={intro_text}
          outroText={outro_text}
          cardImageUrl={card_image_url}
          altText={image_alt}
          timings={timings}
          frequency={frequency}
          duration={duration}
          ctaText={structured_card_cta_text}
          ctaUrl={cta_url}
          isMobile={props.isMobile}
          activeTabIndex={props?.activeTabIndex}
        />
      );

    default:
    // ToDo: Add to Error Logs (Slice)
  }
};

export default sliceHandler;

export const toursTabSliceHandler = (slice: any) => {
  switch (slice.slice_type) {
    case 'tour_list':
      return slice.items;
    default:
      return [];
  }
};
